(function($) {


    $(document).ready(function(){
        // This button will increment the value
        $('[data-quantity="plus"]').click(function(e){
            e.preventDefault();

            var fieldName = $(this).attr('data-field');
            var inputField = $("input[name='" + fieldName + "']");
            var currentVal = parseInt(inputField.val());

            // If is not undefined
            if (!isNaN(currentVal)) {
                // Increment
                inputField.val(currentVal + 1).change();
            } else {
                // Otherwise put a 0 there
                inputField.val(0).change();
            }
        });

        // This button will decrement the value till 0
        $('[data-quantity="minus"]').click(function(e) {
            e.preventDefault();

            var fieldName = $(this).attr('data-field');
            var inputField = $("input[name='" + fieldName + "']");
            var currentVal = parseInt(inputField.val());

            // If it isn't undefined or its greater than 0
            if (!isNaN(currentVal) && currentVal > 0) {
                // Decrement one
                inputField.val(currentVal - 1).change();
            } else {
                // Otherwise put a 0 there
                inputField.val(0).change();
            }
        });
    });

})(jQuery);