(function($) {

    $(document).ready(function(){
        var floated_forms = $('.form--float-labels');

        // Test for placeholder support
        $.support.placeholder = (function(){
            var i = document.createElement('input');
            return 'placeholder' in i;
        })();

        // Hide labels by default if placeholders are supported
        if( $.support.placeholder && floated_forms.is('*') ) {
            var fields = floated_forms.find('li');

            fields.each(function(){
                $(this).addClass('js-hide-label');
            });

            // Code for adding/removing classes here
            fields.find('input, textarea').on('keyup blur focus', function(e){

                // Cache our selectors
                var $this = $(this),
                    $parent = $this.closest('li');

                if (e.type == 'keyup') {
                    if( $this.val() == '' ) {
                        $parent.addClass('js-hide-label');
                    } else {
                        $parent.removeClass('js-hide-label');
                    }
                }
                else if (e.type == 'blur') {
                    if( $this.val() == '' ) {
                        $parent.addClass('js-hide-label');
                    }
                    else {
                        $parent.removeClass('js-hide-label').addClass('js-unhighlight-label');
                    }
                }
                else if (e.type == 'focus') {
                    if( $this.val() !== '' ) {
                        $parent.removeClass('js-unhighlight-label');
                    }
                }
            });
        }
    });

})(jQuery);